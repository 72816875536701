<template>
  <div class="CustomFormForm">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      @update="update"
    >
      <col2-detail>

        <col2-block v-if="pageType === 1">

          <el-form-item label="表单名称">
            {{ form.name }}
          </el-form-item>

          <el-form-item v-if="userType == 106" label="表单编号">
            {{ form.code }}
          </el-form-item>

          <el-form-item label="表单类型">
            {{ formTypeMap[form.formType] }}
          </el-form-item>

          <el-form-item label="表单项目">
            {{ communityListText }}
          </el-form-item>

          <el-form-item label="表单描述">
            {{ form.introduce }}
          </el-form-item>

          <el-form-item label="审批设置">
            {{ approvalMap[form.approval] }}
          </el-form-item>

          <el-form-item v-if="flowKeyVisible" label="绑定流程">
            {{ flowKeyName }}
          </el-form-item>

          <el-form-item label="填写次数">
            {{ fillTypeMap[form.fillType] }}
          </el-form-item>

          <el-form-item label="表单截止">
            {{ validityTypeMap[form.validityType] }}
          </el-form-item>

          <el-form-item v-if="form.validityType === '0'" label="截止时间">
            {{ form.validityTime }}
          </el-form-item>

          <el-form-item label="发布端">
            {{ publishTypeMap[form.publishType] }}
          </el-form-item>

          <el-form-item v-if="configPageShow" label="是否声明">
            {{ isAgreementMap[form.isAgreement] }}
          </el-form-item>

          <el-form-item v-if="configPageShow && ['1', '2', '3'].includes(form.isAgreement)" label="声明">
            <div v-html="form.agreement"></div>
          </el-form-item>

          <el-form-item v-if="configPageShow" label="是否编辑完成页">
            {{ isResultPageMap[form.isResultPage] }}
          </el-form-item>

          <el-form-item v-if="configPageShow && form.isResultPage === '1'" label="完成页">
            <div v-html="form.resultPage"></div>
          </el-form-item>

        </col2-block>

        <col2-block v-else>

          <el-form-item label="表单名称" :rules="[{ required: true, message: '请输入表单名称', trigger: 'change'}]" prop="name">
            <v-input v-model="form.name" placeholder="最多填写20个汉字" :maxlength="20" :width="width" />
          </el-form-item>

          <el-form-item v-if="userType == 106" label="表单编号" prop="code">
            <v-input v-model="form.code" placeholder="请输入表单编号" :width="width" />
          </el-form-item>

          <el-form-item label="表单类型" :rules="[{ required: true, message: '请选择表单类型', trigger: 'change'}]" prop="formType">
            <v-select v-model="form.formType" :options="formTypeOps" placeholder="请选择表单类型" :width="width" />
          </el-form-item>

          <el-form-item label="表单项目" :rules="[{ type: 'array', required: true, message: '请选择表单项目', trigger: 'change' }]" prop="communityIds">
            <chosen-list-panel :list.sync="form.communityIds" @select="isShow = true" />
          </el-form-item>

          <el-form-item label="表单描述" :rules="[{ required: true, message: '请输入表单描述', trigger: 'change' }]" prop="introduce">
            <v-textarea v-model="form.introduce" placeholder="请输入表单描述" :maxlength="2000" :width="642" />
          </el-form-item>

          <el-form-item label="审批设置" :rules="[{ required: true, message: '请选择审批设置', trigger: 'change' }]" prop="approval">
            <checkbox-plus type="radio" :value.sync="form.approval" :options="approvalOps" />
            <span>（注：流程审批只能对单一项目表单配置）</span>
          </el-form-item>

          <el-form-item v-if="flowKeyVisible" label="绑定流程" :rules="[{ required: true, message: '请选择绑定流程', trigger: 'change' }]" prop="flowKey">
            <v-select2 v-model="form.flowKey" placeholder="输入流程名称" v-bind="flowKeyParams" :subjoin="flowKeySubjoin" :width="width" />
          </el-form-item>

          <el-form-item label="填写次数" :rules="[{ required: true, message: '请选择填写次数', trigger: 'change' }]" prop="fillType">
            <checkbox-plus type="radio" :value.sync="form.fillType" :options="fillTypeOps" />
          </el-form-item>

          <el-form-item label="表单截止" :rules="[{ required: true, message: '请选择表单截止', trigger: 'change' }]" prop="validityType">
            <checkbox-plus type="radio" :value.sync="form.validityType" :options="validityTypeOps" />
          </el-form-item>

          <el-form-item v-if="form.validityType === '0'" label="截止时间" :rules="[{ required: true, message: '请选择截止时间', trigger: 'blur' }]" prop="validityTime">
            <v-datepicker type="datetime" v-model="form.validityTime" :minDate="new Date()" />
          </el-form-item>

          <el-form-item label="发布端" :rules="[{ required: true, message: '请选择发布端', trigger: 'change'}]" prop="publishType">
            <v-select v-model="form.publishType" :options="publishTypeOps" placeholder="请选择发布端" :width="width" />
          </el-form-item>

          <el-form-item v-if="configPageShow" label="是否声明" :rules="[{ required: true, message: '请选择是否声明', trigger: 'change' }]" prop="isAgreement">
            <checkbox-plus type="radio" :value.sync="form.isAgreement" :options="isAgreementOps" />
          </el-form-item>

          <el-form-item v-if="configPageShow && ['1', '2', '3'].includes(form.isAgreement)" label="声明" :rules="[{ required: true, message: '请输入声明', trigger: 'change' }]" prop="agreement">
            <v-ueditor v-model="form.agreement" />
          </el-form-item>

          <el-form-item v-if="configPageShow" label="是否编辑完成页" :rules="[{ required: true, message: '请选择是否编辑完成页', trigger: 'change' }]" prop="isResultPage">
            <checkbox-plus type="radio" :value.sync="form.isResultPage" :options="isResultPageOps" />
          </el-form-item>

          <el-form-item v-if="configPageShow && form.isResultPage === '1'" label="完成页" :rules="[{ required: true, message: '请输入完成页', trigger: 'change' }]" prop="resultPage">
            <v-ueditor v-model="form.resultPage" />
          </el-form-item>

        </col2-block>

      </col2-detail>
    </form-panel>
    <multi-select
      title="项目列表"
      :isShow.sync="isShow"
      :searchUrl="getCommunityListURL"
      :headers="communityTableHeader"
      :searchParams="searchParams"
      :responseParams="responseParams"
      :responseKey="responseKey"
      :backFill.sync="form.communityIds"
      :handleData="handleData"
    >
      <template #searchSlot>
        <v-input v-model="searchParams.communityName" label="项目名称" />
        <v-select v-model="searchParams.communityStatus" :options="communityStatusOps" label="项目状态"/>
        <v-select v-model="searchParams.communityStage" :options="communityStageOps" label="项目阶段"/>
      </template>
    </multi-select>
  </div>
</template>

<script>
import { queryDetailURL, createBaseInfoURL, updateBaseInfoURL, getCommunityListURL, getCommunityStageOpsURL, getFlowKeyURL } from './api'
import {
  setCommunityStatusOps, communityStatusMap,
  setFormTypeOps, formTypeMap,
  setPublishTypeOps, publishTypeMap,
  setApprovalOps, approvalMap,
  setFillTypeOps, fillTypeMap,
  setValidityTypeOps, validityTypeMap,
  setIsAgreementOps, isAgreementMap,
  setIsResultPageOps, isResultPageMap
} from './map'
import { vUeditor } from 'components/control'
import { Col2Detail, Col2Block, ChosenListPanel, MultiSelect, CheckboxPlus } from 'components/bussiness'

export default {
  name: 'CustomFormForm',
  components: {
    vUeditor,
    Col2Detail,
    Col2Block,
    ChosenListPanel,
    MultiSelect,
    CheckboxPlus
  },
  data () {
    return {
      pageType: 0,
      width: 200,
      isShow: false,
      formTypeMap,
      publishTypeMap,
      approvalMap,
      fillTypeMap,
      validityTypeMap,
      isAgreementMap,
      isResultPageMap,
      getCommunityListURL,
      communityTableHeader: [
        {
          prop: 'communityName',
          label: '项目名称'
        }, {
          prop: 'communityStatusText',
          label: '项目状态'
        }, {
          prop: 'communityStage',
          label: '项目阶段'
        }, {
          prop: 'regionName',
          label: '所属公司'
        }
      ],
      communityStatusOps: setCommunityStatusOps(1),
      communityStageOps: [{
        text: '全部',
        value: undefined
      }],
      searchParams: {
        communityName: '',
        communityStatus: 0,
        communityStage: undefined,
        isNormal: 1
      },
      responseParams: {
        id: 'id',
        name: 'communityName'
      },
      responseKey: {
        id: 'id',
        name: 'text'
      },
      flowKeySubjoin: {
        communityId: undefined
      },
      formTypeOps: setFormTypeOps(),
      publishTypeOps: setPublishTypeOps(),
      approvalOps: setApprovalOps(),
      fillTypeOps: setFillTypeOps(),
      validityTypeOps: setValidityTypeOps(),
      isAgreementOps: setIsAgreementOps(),
      isResultPageOps: setIsResultPageOps(),
      communityListText: '',
      flowKeyParams: {
        searchUrl: getFlowKeyURL,
      },
      flowKeyName: '',
      form: {
        id: undefined,
        name: '',
        code: '',
        formType: undefined,
        publishType: 2,
        communityIds: [],
        introduce: '',
        approval: '0',
        flowKey: undefined,
        fillType: '0',
        validityType: '1',
        validityTime: '',
        isAgreement: '0',
        agreement: '',
        isResultPage: '0',
        resultPage: ''
      },
      submitConfig: {
        submitUrl: '',
        submitMethod: 'POST',
        queryUrl: queryDetailURL
      }
    }
  },
  created () {
    this.getCommunityStageOps()
  },
  mounted () {
    const { id, view } = this.$route.query
    if (id !== undefined) {
      if (view === 1) {
        this.$setBreadcrumb('查看')
        this.pageType = 1
      } else {
        this.$setBreadcrumb('编辑')
        this.submitConfig.submitUrl = updateBaseInfoURL
      }
      this.$refs.formPanel.getData({
        id
      })
    } else {
      this.$setBreadcrumb('新增')
      this.submitConfig.submitUrl = createBaseInfoURL
      this.approvalOps[1].disabled = true
    }
  },
  watch: {
    'form.communityIds' (val) {
      const communityIds = this.form.communityIds
      if (Array.isArray(communityIds) && communityIds.length === 1) {
        this.flowKeySubjoin = {
          communityId: communityIds[0].id
        }
        this.approvalOps[1].disabled = false
      } else {
        this.flowKeySubjoin = {
          communityId: undefined
        }
        this.approvalOps[1].disabled = true
        if (this.form.approval === '2') {
          this.$nextTick(() => {
            this.form.approval = '0'
          })
        }
      }
    },
    'form.approval': {
      handler (newValue, oldValue) {
        if (['1', '2'].includes(newValue)) {
          this.isAgreementOps[3].disabled = false
        } else {
          this.isAgreementOps[3].disabled = true
          if (this.form.isAgreement === '3') {
            this.$nextTick(() => {
              this.form.isAgreement = '1'
            })
          }
        }
      },
      immediate: true
    }
  },
  computed: {
    userType () {
      return this.$store.state.userInfo.userType
    },
    configPageShow () {
      return this.form.publishType === 2 || this.form.publishType === 3
    },
    flowKeyVisible () {
      return this.form.approval === '2'
    }
  },
  methods: {
    async getCommunityStageOps () {
      const { status, data } = await this.$axios.get(getCommunityStageOpsURL)
      if (status === '100') {
        if (Array.isArray(data) && data.length) {
          data.forEach(item => {
            this.communityStageOps.push({
              text: item.value,
              value: item.id
            })
          })
        }
      }
    },
    handleData (data) {
      data.communityStatusText = communityStatusMap[data.communityStatus]
    },
    update (data) {
      if (this.pageType === 1) {
        this.flowKeyName = data.flowKeyName
        if (Array.isArray(data.communityIds) && data.communityIds.length) {
          const communityList = []
          data.communityIds.forEach(item => {
            communityList.push(item.name)
          })
          this.communityListText = communityList.join('，')
        }
      } else {
        if (Array.isArray(data.communityIds) && data.communityIds.length) {
          data.communityIds = data.communityIds.map(item => {
            return {
              text: item.name,
              id: item.id
            }
          })
        }
      }
      data.approval = String(data.approval)
      data.fillType = String(data.fillType)
      data.validityType = String(data.validityType)
      data.isAgreement = String(data.isAgreement)
      data.isResultPage = String(data.isResultPage)
      Object.keys(this.form).forEach(key => {
        if (data[key] !== undefined) {
          this.form[key] = data[key]
        }
      })
    },
    submitBefore (data) {
      const communityIds = []
      if (Array.isArray(data.communityIds) && data.communityIds.length) {
        data.communityIds.forEach(item => {
          communityIds.push(item.id)
        })
      }
      const communityNames = []
      if (Array.isArray(this.form.communityIds) && this.form.communityIds.length) {
        this.form.communityIds.forEach(item => {
          communityNames.push(item.text)
        })
      }
      data.communityIds = communityIds
      data.dataObject = `${data.name}-${communityNames.join('、')}`

      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.CustomFormForm {

}
</style>
