var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "CustomFormForm" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _vm.pageType === 1
                ? _c(
                    "col2-block",
                    [
                      _c("el-form-item", { attrs: { label: "表单名称" } }, [
                        _vm._v(
                          "\n          " + _vm._s(_vm.form.name) + "\n        "
                        ),
                      ]),
                      _vm.userType == 106
                        ? _c("el-form-item", { attrs: { label: "表单编号" } }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.form.code) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                      _c("el-form-item", { attrs: { label: "表单类型" } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.formTypeMap[_vm.form.formType]) +
                            "\n        "
                        ),
                      ]),
                      _c("el-form-item", { attrs: { label: "表单项目" } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.communityListText) +
                            "\n        "
                        ),
                      ]),
                      _c("el-form-item", { attrs: { label: "表单描述" } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.form.introduce) +
                            "\n        "
                        ),
                      ]),
                      _c("el-form-item", { attrs: { label: "审批设置" } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.approvalMap[_vm.form.approval]) +
                            "\n        "
                        ),
                      ]),
                      _vm.flowKeyVisible
                        ? _c("el-form-item", { attrs: { label: "绑定流程" } }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.flowKeyName) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                      _c("el-form-item", { attrs: { label: "填写次数" } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.fillTypeMap[_vm.form.fillType]) +
                            "\n        "
                        ),
                      ]),
                      _c("el-form-item", { attrs: { label: "表单截止" } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.validityTypeMap[_vm.form.validityType]) +
                            "\n        "
                        ),
                      ]),
                      _vm.form.validityType === "0"
                        ? _c("el-form-item", { attrs: { label: "截止时间" } }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.form.validityTime) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                      _c("el-form-item", { attrs: { label: "发布端" } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.publishTypeMap[_vm.form.publishType]) +
                            "\n        "
                        ),
                      ]),
                      _vm.configPageShow
                        ? _c("el-form-item", { attrs: { label: "是否声明" } }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.isAgreementMap[_vm.form.isAgreement]
                                ) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                      _vm.configPageShow &&
                      ["1", "2", "3"].includes(_vm.form.isAgreement)
                        ? _c("el-form-item", { attrs: { label: "声明" } }, [
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(_vm.form.agreement),
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm.configPageShow
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "是否编辑完成页" } },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.isResultPageMap[_vm.form.isResultPage]
                                  ) +
                                  "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.configPageShow && _vm.form.isResultPage === "1"
                        ? _c("el-form-item", { attrs: { label: "完成页" } }, [
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(_vm.form.resultPage),
                              },
                            }),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                : _c(
                    "col2-block",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "表单名称",
                            rules: [
                              {
                                required: true,
                                message: "请输入表单名称",
                                trigger: "change",
                              },
                            ],
                            prop: "name",
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "最多填写20个汉字",
                              maxlength: 20,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.userType == 106
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "表单编号", prop: "code" } },
                            [
                              _c("v-input", {
                                attrs: {
                                  placeholder: "请输入表单编号",
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.code,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "code", $$v)
                                  },
                                  expression: "form.code",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "表单类型",
                            rules: [
                              {
                                required: true,
                                message: "请选择表单类型",
                                trigger: "change",
                              },
                            ],
                            prop: "formType",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.formTypeOps,
                              placeholder: "请选择表单类型",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.formType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "formType", $$v)
                              },
                              expression: "form.formType",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "表单项目",
                            rules: [
                              {
                                type: "array",
                                required: true,
                                message: "请选择表单项目",
                                trigger: "change",
                              },
                            ],
                            prop: "communityIds",
                          },
                        },
                        [
                          _c("chosen-list-panel", {
                            attrs: { list: _vm.form.communityIds },
                            on: {
                              "update:list": function ($event) {
                                return _vm.$set(
                                  _vm.form,
                                  "communityIds",
                                  $event
                                )
                              },
                              select: function ($event) {
                                _vm.isShow = true
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "表单描述",
                            rules: [
                              {
                                required: true,
                                message: "请输入表单描述",
                                trigger: "change",
                              },
                            ],
                            prop: "introduce",
                          },
                        },
                        [
                          _c("v-textarea", {
                            attrs: {
                              placeholder: "请输入表单描述",
                              maxlength: 2000,
                              width: 642,
                            },
                            model: {
                              value: _vm.form.introduce,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "introduce", $$v)
                              },
                              expression: "form.introduce",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "审批设置",
                            rules: [
                              {
                                required: true,
                                message: "请选择审批设置",
                                trigger: "change",
                              },
                            ],
                            prop: "approval",
                          },
                        },
                        [
                          _c("checkbox-plus", {
                            attrs: {
                              type: "radio",
                              value: _vm.form.approval,
                              options: _vm.approvalOps,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(_vm.form, "approval", $event)
                              },
                            },
                          }),
                          _c("span", [
                            _vm._v("（注：流程审批只能对单一项目表单配置）"),
                          ]),
                        ],
                        1
                      ),
                      _vm.flowKeyVisible
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "绑定流程",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择绑定流程",
                                    trigger: "change",
                                  },
                                ],
                                prop: "flowKey",
                              },
                            },
                            [
                              _c(
                                "v-select2",
                                _vm._b(
                                  {
                                    attrs: {
                                      placeholder: "输入流程名称",
                                      subjoin: _vm.flowKeySubjoin,
                                      width: _vm.width,
                                    },
                                    model: {
                                      value: _vm.form.flowKey,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "flowKey", $$v)
                                      },
                                      expression: "form.flowKey",
                                    },
                                  },
                                  "v-select2",
                                  _vm.flowKeyParams,
                                  false
                                )
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "填写次数",
                            rules: [
                              {
                                required: true,
                                message: "请选择填写次数",
                                trigger: "change",
                              },
                            ],
                            prop: "fillType",
                          },
                        },
                        [
                          _c("checkbox-plus", {
                            attrs: {
                              type: "radio",
                              value: _vm.form.fillType,
                              options: _vm.fillTypeOps,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(_vm.form, "fillType", $event)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "表单截止",
                            rules: [
                              {
                                required: true,
                                message: "请选择表单截止",
                                trigger: "change",
                              },
                            ],
                            prop: "validityType",
                          },
                        },
                        [
                          _c("checkbox-plus", {
                            attrs: {
                              type: "radio",
                              value: _vm.form.validityType,
                              options: _vm.validityTypeOps,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.form,
                                  "validityType",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.form.validityType === "0"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "截止时间",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择截止时间",
                                    trigger: "blur",
                                  },
                                ],
                                prop: "validityTime",
                              },
                            },
                            [
                              _c("v-datepicker", {
                                attrs: {
                                  type: "datetime",
                                  minDate: new Date(),
                                },
                                model: {
                                  value: _vm.form.validityTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "validityTime", $$v)
                                  },
                                  expression: "form.validityTime",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "发布端",
                            rules: [
                              {
                                required: true,
                                message: "请选择发布端",
                                trigger: "change",
                              },
                            ],
                            prop: "publishType",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.publishTypeOps,
                              placeholder: "请选择发布端",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.publishType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "publishType", $$v)
                              },
                              expression: "form.publishType",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.configPageShow
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否声明",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择是否声明",
                                    trigger: "change",
                                  },
                                ],
                                prop: "isAgreement",
                              },
                            },
                            [
                              _c("checkbox-plus", {
                                attrs: {
                                  type: "radio",
                                  value: _vm.form.isAgreement,
                                  options: _vm.isAgreementOps,
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.form,
                                      "isAgreement",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.configPageShow &&
                      ["1", "2", "3"].includes(_vm.form.isAgreement)
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "声明",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入声明",
                                    trigger: "change",
                                  },
                                ],
                                prop: "agreement",
                              },
                            },
                            [
                              _c("v-ueditor", {
                                model: {
                                  value: _vm.form.agreement,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "agreement", $$v)
                                  },
                                  expression: "form.agreement",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.configPageShow
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否编辑完成页",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择是否编辑完成页",
                                    trigger: "change",
                                  },
                                ],
                                prop: "isResultPage",
                              },
                            },
                            [
                              _c("checkbox-plus", {
                                attrs: {
                                  type: "radio",
                                  value: _vm.form.isResultPage,
                                  options: _vm.isResultPageOps,
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.form,
                                      "isResultPage",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.configPageShow && _vm.form.isResultPage === "1"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "完成页",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入完成页",
                                    trigger: "change",
                                  },
                                ],
                                prop: "resultPage",
                              },
                            },
                            [
                              _c("v-ueditor", {
                                model: {
                                  value: _vm.form.resultPage,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "resultPage", $$v)
                                  },
                                  expression: "form.resultPage",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _c("multi-select", {
        attrs: {
          title: "项目列表",
          isShow: _vm.isShow,
          searchUrl: _vm.getCommunityListURL,
          headers: _vm.communityTableHeader,
          searchParams: _vm.searchParams,
          responseParams: _vm.responseParams,
          responseKey: _vm.responseKey,
          backFill: _vm.form.communityIds,
          handleData: _vm.handleData,
        },
        on: {
          "update:isShow": function ($event) {
            _vm.isShow = $event
          },
          "update:is-show": function ($event) {
            _vm.isShow = $event
          },
          "update:backFill": function ($event) {
            return _vm.$set(_vm.form, "communityIds", $event)
          },
          "update:back-fill": function ($event) {
            return _vm.$set(_vm.form, "communityIds", $event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "项目名称" },
                  model: {
                    value: _vm.searchParams.communityName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "communityName", $$v)
                    },
                    expression: "searchParams.communityName",
                  },
                }),
                _c("v-select", {
                  attrs: { options: _vm.communityStatusOps, label: "项目状态" },
                  model: {
                    value: _vm.searchParams.communityStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "communityStatus", $$v)
                    },
                    expression: "searchParams.communityStatus",
                  },
                }),
                _c("v-select", {
                  attrs: { options: _vm.communityStageOps, label: "项目阶段" },
                  model: {
                    value: _vm.searchParams.communityStage,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "communityStage", $$v)
                    },
                    expression: "searchParams.communityStage",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }